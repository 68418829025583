// const isDevOnly = import.meta.env.VITE_AUTH0_ISSUER === 'https://dev-kktqv2saykjjurmt.us.auth0.com'

export const variables = {
  appName: 'Turbomaps',
  supportLink: '/docs/contact',
  feedbackLink: 'https://fvljhiad2nf.typeform.com/to/vCDDqR9Z',
  startingZoom: 13,
  startingRotate: 0,
  selectionRadius: 5,
  selectionColor: [30, 144, 255],
  featureFlags: {
    alternateProjectionsAvailable: false,
    terrainAvailable: false,
    deckDebugMode: false, // set this to true to render the normally invisible deck layer
  },
  default: {
    circleFillColor: 'rgba(41,128,185,1)',
    circleStrokeColor: 'rgba(255,255,255,1)',
    circleStrokeWidth: 1,
    circleRadius: 6.5,
    markerFillColor: 'rgba(231,76,60,1)',
    markerWidth: 10,
    fillColor: 'rgba(85,85,85,0.25)',
    strokeColor: 'rgba(0,0,0,1)',
    strokeDasharray: [],
    strokeWidth: 3,
    textAlignment: 'auto',
    textColor: 'rgba(34,34,34,1)',
    textFont: 'Open Sans Semibold',
    textSize: 14,
  },
  defaultDark: {
    circleFillColor: 'rgba(41,128,185,1)',
    circleStrokeColor: 'rgba(255,255,255,1)',
    fillColor: 'rgba(170,170,170,0.25)',
    strokeColor: 'rgba(255,255,255,1)',
    textColor: 'rgba(238,238,238,1)',
  },
  auth0: {
    issuer: import.meta.env.VITE_AUTH0_ISSUER,
    clientId: import.meta.env.VITE_AUTH0_CLIENTID,
    // WARNING: The client secret is used for SSR, but we don't want it compiled into our JS
    // env.AUTH0_CLIENTSECRET
  },
  mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN,
  identifiers: {
    // these are just random identifiers to sign public requests with
    // this is so we can still maintain an auth (signed) between this server and the upstream server - no direct public access
    user_id: '0492516c-ca69-48fb-bec0-a53683151fe4',
    tenant_id: '37af941a-df22-4f72-a173-91d129a4a990',
  },
}
